@import "~perfect-scrollbar";
@import "../fontawesome/css/all.css";

html,
body {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.text-dark-50 {
  color: #7e8299 !important;
}

@media (min-width: 992px) {
  .aside-fixed .wrapper {
    padding-left: 290px !important;
  }

  .aside-fixed.aside-minimize:not(.aside-minimize-hover) .wrapper {
    padding-left: 95px !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // -webkit-box-shadow: 0 0 0 30px white inset !important;
    // transition: background-color 5000s ease-in-out 0s;
  }

  .rdt_TableRow {
    padding: 0.8rem 0;
  }

  .dropdown {
    button {
      width: 100%;
    }
  }

  .dropdown-toggle {
    width: 100% !important;

    &::after {
      content: "\f107" !important;
      font-family: "Font Awesome 5 Pro" !important;
      font-size: 1rem !important;
    }
  }

  .dropdown-item {
    font-weight: 400 !important;
  }
}

.swal2-icon {
  margin: 2.5em auto 0.6em !important;
}

.q-pointer-event-none {
  pointer-events: none !important;
}

.q-max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.q-max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.q-max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.q-max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.q-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.q-hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.q-aspect-ratio-thumbnail {
  aspect-ratio: 16/10;
  object-fit: cover;
  overflow: hidden;
}

.q-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.duy-custom-input-image {
  max-width: 200px !important;
  max-height: 300px !important;
  border-radius: 10px;
}
